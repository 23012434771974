import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '../components/base/button/button';
import { IconType } from '../components/base/icon/icon.types';
import ConnectStepPage from '../components/pages/connect-step/connect-step';
import PermissionItem from '../components/partial/permission-item/permission-item';
import { openLinkInNewTab } from '../helpers/navigation';
import { Routes } from '../routes.enum';
import { loadAccountPermissions, loadAccountStatus } from '../store/reducers/account-slice';
import { RootState } from '../store/store';

const ConnectThirdStep = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { connectSteps, isLoading, permissions, isLoadingStatus, isLoadingPermissions } = useSelector(
        (state: RootState) => state.account,
    );

    useEffect(() => {
        const intervalCheckStatus = setInterval(() => {
            if (!isLoading) {
                dispatch(loadAccountStatus());
            }
        }, 10000);

        return () => clearInterval(intervalCheckStatus);
    }, [connectSteps, dispatch, isLoading]);

    const handleSellerCentral = () => {
        const fourthStep = connectSteps?.['4'];

        fourthStep?.url && openLinkInNewTab(fourthStep.url);
    };

    const handleCheckClick = () => {
        dispatch(loadAccountPermissions());
    };

    const isLoadingVisible = isLoadingStatus || isLoading || isLoadingPermissions;

    return (
        <ConnectStepPage currentStep={2}>
            <div className="title">Set Permissions</div>
            <div className="description">
                Follow the link below, sign in using your Amazon account, if needed, and you will see a page similar to
                what you see to the right
            </div>
            <Button onClick={handleSellerCentral} iconType={IconType.Vector} style="white">
                Seller Central access page
            </Button>
            <ul className="connect-step__list-permissions">
                <li>
                    {permissions.map((permissionItem, index) => (
                        <PermissionItem
                            key={index}
                            havePermission={permissionItem.status}
                            title={permissionItem.title}
                            description={permissionItem.description}
                        />
                    ))}
                </li>
            </ul>

            <div className="connect-step__controls connect-step__controls--permissions">
                <Button onClick={handleCheckClick} isLoading={isLoadingVisible} style="white" fullWidth>
                    Check Access
                </Button>
                <Button onClick={() => navigate(Routes.SuccessConnected)} fullWidth>
                    Done
                </Button>
            </div>
            {isLoadingVisible && (
                <div className="check-access-tip">
                    The permissions check will take about 45 seconds. Stay at the screen until the end of the check
                    process
                </div>
            )}
        </ConnectStepPage>
    );
};

export default ConnectThirdStep;
