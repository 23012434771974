import React from 'react';
import { IconType } from '../icon/icon.types';
import Icon from '../icon/icon';
import classNames from 'classnames';

import './button.scss';

export interface ButtonProps {
    className?: string;
    children: React.ReactElement | string;
    style?: 'default' | 'white';
    type?: 'button' | 'submit';
    onClick?: () => void;
    fullWidth?: boolean;
    iconType?: IconType;
    isLoading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
    children,
    style = 'default',
    type = 'submit',
    onClick,
    fullWidth = false,
    iconType,
    className = '',
    isLoading,
}) => {
    let content = (
        <>
            {children}
            {iconType && (
                <div className="button__icon">
                    <Icon type={iconType} />
                </div>
            )}
        </>
    );

    return (
        <button
            type={type}
            onClick={onClick}
            className={classNames(`button button--style-${style} ${className}`, {
                'button--full-width': fullWidth,
                'button--loading': isLoading,
            })}
        >
            {isLoading ? <Icon type={IconType.Loading} className="button__loader" /> : content}
        </button>
    );
};

export default Button;
