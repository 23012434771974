import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '../../components/base/button/button';
import Input from '../../components/base/input/input';
import ContentCentering from '../../components/partial/content-centering/content-centering';
import { getActiveConnectStepRoute } from '../../helpers/account';
import { activateCode } from '../../store/reducers/account-slice';
import { RootState } from '../../store/store';
import downloadAppStore from './../../assets/images/download-app-store.png';
import downloadGooglePlay from './../../assets/images/download-google-play.png';

import './activate.scss';

const Activate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        auth: { isAuth },
        account: { isLoading, error: accountError, connectSteps, isLoadingStatus },
    } = useSelector((state: RootState) => state);

    useEffect(() => {
        isAuth && connectSteps && navigate(getActiveConnectStepRoute(connectSteps));
    }, [connectSteps, navigate, isAuth]);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<{ code: string }>();

    const onSubmit = handleSubmit((values) => {
        dispatch(
            activateCode({
                code: values.code,
            }),
        );
    });

    return (
        <ContentCentering>
            <form onSubmit={onSubmit}>
                <div className="title">Connect your account</div>
                <div className="description">
                    You can connect any number of accounts for different stores in your profile
                </div>

                <Controller
                    control={control}
                    name="code"
                    render={({ field: { onChange, value } }) => (
                        <Input
                            placeholder="____-____"
                            classNameLabel="activate__input-code"
                            mask="****-****"
                            name="test"
                            value={value}
                            onChange={onChange}
                            error={errors.code?.message || accountError}
                        />
                    )}
                    rules={{
                        required: 'Enter code',
                        validate: (value: string) => value.length === 9 || 'Code length must be 8',
                    }}
                />

                <div className="activate__apps">
                    <div className="activate__apps-description">
                        If you don`t have the code, you need to set up the Alarma mobile apps. In the application, you
                        need to log in, go to the profile, the accounts section and add a new account.
                    </div>
                    <div className="activate__apps-links">
                        <a target="_blank" href="https://alrm.online" rel="noopener noreferrer">
                            <img src={downloadGooglePlay} alt="App link Google play" />
                        </a>
                        <a target="_blank" href="https://alrm.online" rel="noopener noreferrer">
                            <img src={downloadAppStore} alt="App link AppStore" />
                        </a>
                    </div>
                </div>
                <div className="connect-step__controls">
                    <Button isLoading={isLoading || isLoadingStatus} fullWidth>
                        Connect Account
                    </Button>
                </div>
            </form>
        </ContentCentering>
    );
};

export default Activate;
