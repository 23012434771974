import React from 'react';
import { Outlet } from 'react-router';

import Header from '../header/header';

import './layout.scss';

const Layout = () => (
    <div className="layout">
        <Header />
        <div className="layout__wrapper">
            <div className="layout__top">
                <div className="layout__caption">Alarma</div>
                <h2>Connect your Amazon Account</h2>
            </div>
            <div className="layout__content">
                <Outlet />
            </div>
        </div>
    </div>
);

export default Layout;
