import React from 'react';
import { IconProps, IconType } from './icon.types';
import { ReactComponent as Check } from '../../../assets/icons/check.svg';
import { ReactComponent as CheckCircle } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as Copy } from '../../../assets/icons/copy.svg';
import { ReactComponent as CrossCircle } from '../../../assets/icons/cross-circle.svg';
import { ReactComponent as Connect } from '../../../assets/icons/connect.svg';
import { ReactComponent as Vector } from '../../../assets/icons/vector.svg';
import { ReactComponent as CrossError } from '../../../assets/icons/cross-error.svg';
import { ReactComponent as Loading } from '../../../assets/icons/loading.svg';

import './icon.scss';

const Icon: React.FC<IconProps> = (props) => {
    const { type, rotate, ...otherProps } = props;

    if (rotate) {
        otherProps.className = otherProps.className + ' icon--rotate';
    }

    switch (type) {
        case IconType.Check:
            return <Check {...otherProps} />;
        case IconType.CheckCircle:
            return <CheckCircle {...otherProps} />;
        case IconType.Copy:
            return <Copy {...otherProps} />;
        case IconType.CrossCircle:
            return <CrossCircle {...otherProps} />;
        case IconType.Vector:
            return <Vector {...otherProps} />;
        case IconType.Connect:
            return <Connect {...otherProps} />;
        case IconType.CrossError:
            return <CrossError {...otherProps} />;
        case IconType.Loading:
            return <Loading {...otherProps} />;
        default:
            return null;
    }
};

export default Icon;
