import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { createBrowserHistory } from 'history';
import { generatePath } from 'react-router';

import { ApiErrors } from '../enums/api-errors';
import { clearToken, getToken } from '../helpers/token';
import { Routes } from '../routes.enum';

export interface ApiResponse<T> {
    data: T;
    error?: string;
    status?: number;
}

export const history = createBrowserHistory();

let Api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
});

Api.interceptors.request.use((config) => {
    const token = getToken();

    config.headers!.Authorization = `Bearer ${token}`;
    return config;
});
Api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        if (error.response?.status === ApiErrors.notAuthorized) {
            clearToken();

            if (history.location.pathname !== Routes.Activate) {
                history.push(Routes.Activate);
            }
        }

        throw error.response?.data;
    },
);

export default Api;
