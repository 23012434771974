import React from 'react';
import classNames from 'classnames';

import './connect-steps.scss';

interface ConnectStepsProps {
    currentStep: number;
}

const ConnectSteps: React.FC<ConnectStepsProps> = ({ currentStep }) => {
    return (
        <div className="connect-steps">
            {Array(3)
                .fill('')
                .map((item, index) => (
                    <div
                        key={index}
                        className={classNames('connect-steps__item', {
                            'connect-steps__item--active': currentStep === index,
                            'connect-steps__item--prev': currentStep > index,
                        })}
                    />
                ))}
        </div>
    );
};

export default ConnectSteps;
