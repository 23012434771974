import React from 'react';
import Icon from '../../base/icon/icon';
import { IconType } from '../../base/icon/icon.types';

import './permission-item.scss';
import classNames from 'classnames';

export interface PermissionItemProps {
    havePermission?: boolean;
    title: string;
    description: string;
}

const PermissionItem: React.FC<PermissionItemProps> = ({ havePermission, title, description }) => {
    return (
        <div className="permission-item">
            <div className="permission-item__top">
                <div
                    className={classNames('permission-item__icon', {
                        'permission-item__icon--success': havePermission,
                        'permission-item__icon--failed': !havePermission,
                    })}
                >
                    <Icon type={havePermission ? IconType.CheckCircle : IconType.CrossCircle} />
                </div>
                <div className="permission-item__title">{title}</div>
            </div>
            <div className="permission-item__description">{description}</div>
        </div>
    );
};

export default PermissionItem;
