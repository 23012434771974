import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/store';
import Icon from '../../base/icon/icon';
import { IconType } from '../../base/icon/icon.types';
import Loader from '../../base/loader/loader';
import { Modal } from '../../base/modal/modal';

interface CheckAccessModalProps {
    setVisibleModal: (nextValue: boolean) => void;
    onConfirm: () => void;
}

const CheckAccessModal: React.FC<CheckAccessModalProps> = ({ setVisibleModal, onConfirm }) => {
    const { isLoadingStatus } = useSelector((state: RootState) => state.account);

    return (
        <Modal
            onConfirm={onConfirm}
            confirmButtonText={isLoadingStatus ? '' : 'Try Again'}
            onClose={() => setVisibleModal(false)}
        >
            {isLoadingStatus ? (
                <div>
                    <div className="connect-step__modal-icon">
                        <Loader />
                    </div>
                    We are getting access for your account, please wait
                </div>
            ) : (
                <div>
                    <div className="connect-step__modal-icon">
                        <Icon type={IconType.CrossError} />
                    </div>
                    We were unable to get access to your account
                </div>
            )}
        </Modal>
    );
};
export default CheckAccessModal;
