import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';

interface ThunkAPIConfig {
    rejectValue: string;
}

export const createMyOwnAsyncThunk = <Returned, ThunkArg = any>(
    type: string,
    thunk: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkAPIConfig>,
): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> =>
    createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(type, async (arg, thunkAPI) => {
        try {
            return await thunk(arg, thunkAPI);
        } catch (err) {
            const error = err as { message?: string };

            return thunkAPI.rejectWithValue(error?.message || error.toString());
        }
    });
