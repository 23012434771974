import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '../components/base/button/button';
import ContentCentering from '../components/partial/content-centering/content-centering';
import { logout } from '../store/reducers/auth-slice';
import congratulationsImage from './../assets/images/congratulations.png';

const SuccessConnected = () => {
    const dispatch = useDispatch();

    const handleClickAnotherCodeButton = () => {
        dispatch(logout());
    };

    return (
        <ContentCentering>
            <div className="connect-step__success-connected-image">
                <img src={congratulationsImage} alt="congratulations" />
            </div>
            <div className="title">Congratulations!</div>
            <div className="description">Your store has been successfully connected</div>
            <div className="description--bold">
                Please return to the mobile app to complete the connected account setup.
            </div>

            <div className="connect-step__controls">
                <Button onClick={handleClickAnotherCodeButton} style="white" fullWidth>
                    Enter another code
                </Button>
            </div>
        </ContentCentering>
    );
};

export default SuccessConnected;
