import copy from 'copy-to-clipboard';
import React, { useState } from 'react';

import Icon from '../../base/icon/icon';
import { IconType } from '../../base/icon/icon.types';

import './copyable-text.scss';

export interface CopyableTextProps {
    textToCopy: string;
    titleBeforeCopy: string;
    titleAfterCopy: string;
    className?: string;
}

let copiedTimeout: NodeJS.Timeout;

const CopyableText: React.FC<CopyableTextProps> = ({ textToCopy, titleBeforeCopy, titleAfterCopy, className = '' }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        copy(textToCopy);
        setCopied(true);
        clearTimeout(copiedTimeout);
        copiedTimeout = setTimeout(() => setCopied(false), 3000);
    };

    return (
        <button className={`copyable-text ${className}`} onClick={handleCopy}>
            <div className="copyable-text__icon">
                <Icon type={copied ? IconType.Check : IconType.Copy} />
            </div>
            <div className="copyable-text__title">{copied ? titleAfterCopy : titleBeforeCopy}</div>
        </button>
    );
};

export default CopyableText;
