import Api, { ApiResponse } from '../api';
import { AccountStatus } from '../models/AccountStatus';
import { ActivateState } from '../models/Activate';

export class AccountApi {
    static activateCode({ code }: { code: string }) {
        return Api.get<ActivateState>(`/accounts/activate?code=${code}`);
    }
    static loadStatus({ accountId }: { accountId: string }) {
        return Api.get<ApiResponse<AccountStatus>>(`/accounts/${accountId}/status`);
    }
    static loadPermissions({ accountId }: { accountId: string }) {
        return Api.get<ApiResponse<AccountStatus>>(`/accounts/${accountId}/permissions`);
    }
}
