import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/base/button/button';
import { IconType } from '../components/base/icon/icon.types';
import ConnectStepPage from '../components/pages/connect-step/connect-step';
import { openLinkInNewTab } from '../helpers/navigation';
import { loadAccountStatus, loadAccountStatusForce } from '../store/reducers/account-slice';
import { RootState } from '../store/store';

const ConnectFirstStep = () => {
    const dispatch = useDispatch();
    const { connectSteps, isLoading, isLoadingStatus, isLoadingStatusForce } = useSelector(
        (state: RootState) => state.account,
    );

    const handleSellerCentral = () => {
        const secondStep = connectSteps?.['2'];

        secondStep?.url && openLinkInNewTab(secondStep.url);
    };

    useEffect(() => {
        const intervalCheckStatus = setInterval(() => {
            if (!isLoading) {
                dispatch(loadAccountStatus());
            }
        }, 1000);

        return () => clearInterval(intervalCheckStatus);
    }, [connectSteps, dispatch, isLoading]);

    const handleCheckClick = () => {
        dispatch(loadAccountStatusForce());
    };

    return (
        <ConnectStepPage currentStep={0}>
            <div className="title">Authorize in Seller Central</div>
            <div className="description">
                Authorizing an application gives an application the ability to view or edit information about your
                Amazon business and take action on your Selling Partner account
            </div>
            <div className="connect-step__controls">
                <Button
                    onClick={handleCheckClick}
                    className="check-access-btn"
                    isLoading={isLoadingStatusForce}
                    style="white"
                    fullWidth
                >
                    Check Access
                </Button>
                <Button onClick={handleSellerCentral} iconType={IconType.Vector} fullWidth>
                    Go to Seller Central
                </Button>
            </div>
            {isLoadingStatusForce && (
                <div className="check-access-tip">
                    The permissions check will take about 45 seconds. Stay at the screen until the end of the check
                    process
                </div>
            )}
        </ConnectStepPage>
    );
};

export default ConnectFirstStep;
