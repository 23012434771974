import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, generatePath } from 'react-router';

import { getAccountIdLS } from '../../../helpers/account';
import { getToken } from '../../../helpers/token';
import { Routes } from '../../../routes.enum';
import { RootState } from '../../../store/store';

interface RequireAuthProps {
    children: React.ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
    const { isAuth } = useSelector((state: RootState) => state.auth);
    const token = getToken();
    const accountId = getAccountIdLS();

    return !accountId || (!isAuth && !token) ? <Navigate to={Routes.Activate} /> : <>{children}</>;
};

export default RequireAuth;
