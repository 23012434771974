interface IHandleAction {
    isLoading: boolean;
    error?: string;
    [propName: string]: any;
}

export const handlePendingAction = (state: IHandleAction) => {
    state.isLoading = true;
    state.error = undefined;
};

export const handleFulfilledAction = (state: IHandleAction) => {
    state.isLoading = false;
    state.error = undefined;
};

export const handleRejectedAction = (state: IHandleAction, error?: string) => {
    state.isLoading = false;
    state.error = error;
};
