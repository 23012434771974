import React from 'react';

import './list.scss';

interface ListProps {
    items: string[];
    withIndex?: boolean;
    className?: string;
}

const List: React.FC<ListProps> = ({ items, withIndex, className = '' }) => (
    <ul className={`list ${className}`}>
        {items.map((item, index) => (
            <li key={index} className="list__item">
                {withIndex ? (
                    <div className="list__item-marker list__item-number">{index + 1}.</div>
                ) : (
                    <div className="list__item-marker list__item-marker-dot" />
                )}
                <div className="list__item-content">{item}</div>
            </li>
        ))}
    </ul>
);

export default List;
