import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/base/button/button';
import { IconType } from '../components/base/icon/icon.types';
import List from '../components/base/list/list';
import CheckAccessModal from '../components/pages/connect-step/check-access-modal';
import ConnectStepPage from '../components/pages/connect-step/connect-step';
import CopyableText from '../components/partial/copyable-text/copyable-text';
import { openLinkInNewTab } from '../helpers/navigation';
import { loadAccountStatus } from '../store/reducers/account-slice';
import { RootState } from '../store/store';

const listFeatures = [
    'Automated Review Requester',
    'Automated Review Puncher',
    'Product Review Analizer',
    'Conversion Analytics',
    'Inventory Alerts',
];
const addUserSteps = [
    'In the “Name” field enter “Alarma” or a name of your choice.',
    'Copy the email you see below and paste itto the “Email Address” field.',
    'Press “Send Invitation”',
];

const ConnectSecondStep = () => {
    const dispatch = useDispatch();
    const [visibleModal, setVisibleModal] = useState(false);
    const { connectSteps } = useSelector((state: RootState) => state.account);

    const handleCheckClick = () => {
        setVisibleModal(true);
        dispatch(loadAccountStatus());
    };

    const handleAddUser = () => {
        const thirdStep = connectSteps?.['3'];

        thirdStep?.url && openLinkInNewTab(thirdStep.url);
    };

    const userEmail = connectSteps?.['3'].email || '';

    return (
        <ConnectStepPage currentStep={1}>
            {visibleModal && <CheckAccessModal onConfirm={handleCheckClick} setVisibleModal={setVisibleModal} />}

            <div className="title">Seller Central Access</div>
            <div className="description">We provide adtitional features if you connect your SellerCentral</div>
            <List items={listFeatures} className="connect-step__list" />

            <div className="title">Add Alarma User</div>
            <div className="description">
                Follow the link below, sign in using your Amazon account, if needed, and you will see a page similar to
                what you see to the right
            </div>
            <List items={addUserSteps} withIndex className="connect-step__list" />

            {userEmail && (
                <>
                    <div className="description">{userEmail}</div>
                    <CopyableText textToCopy={userEmail} titleBeforeCopy="Copy Email" titleAfterCopy="Email copied" />
                </>
            )}

            <div className="connect-step__controls">
                <Button onClick={handleAddUser} iconType={IconType.Vector} fullWidth className="connect-step__button">
                    Add Alarma User
                </Button>
                <Button onClick={handleCheckClick} style="white" fullWidth className="connect-step__button">
                    Check Access
                </Button>
            </div>
        </ConnectStepPage>
    );
};

export default ConnectSecondStep;
