import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import { ApiResponse } from '../../api/api';
import { AccountApi } from '../../api/controllers/Account';
import { AccountStatus } from '../../api/models/AccountStatus';
import { ConnectSteps } from '../../api/models/ConnectSteps';
import { Permission } from '../../api/models/Permission';
import { getAccountIdLS, setAccountIdLS } from '../../helpers/account';
import { setToken } from '../../helpers/token';
import { handleFulfilledAction, handlePendingAction, handleRejectedAction } from '../basic-actions';
import { createMyOwnAsyncThunk } from '../config';
import { setAuth } from './../reducers/auth-slice';

export interface AccountState {
    id?: string;
    connectSteps?: ConnectSteps;
    isLoading: boolean;
    isLoadingStatus: boolean;
    isLoadingPermissions: boolean;
    isLoadingStatusForce: boolean;
    error?: string;
    permissions: Permission[];
}

const initialState: AccountState = {
    id: undefined,
    connectSteps: undefined,
    isLoading: false,
    isLoadingStatus: false,
    isLoadingPermissions: false,
    isLoadingStatusForce: false,
    error: undefined,
    permissions: [],
};

export const loadAccountPermissions = createMyOwnAsyncThunk<{}, undefined>(
    'accountSlice/loadAccountPermissions',
    async (_, { dispatch }) => {
        const accountId = getAccountIdLS();

        if (!accountId) return false;

        await AccountApi.loadPermissions({
            accountId,
        });

        dispatch(loadAccountStatus());
    },
);

const loadAccountStatusBasic = async (_: undefined, { dispatch }: { dispatch: Dispatch }) => {
    const accountId = getAccountIdLS();

    if (!accountId) return false;

    const { data: responseData }: AxiosResponse<ApiResponse<AccountStatus>> = await AccountApi.loadStatus({
        accountId,
    });

    const nextConnectSteps = {
        '1': responseData.data['1'],
        '2': responseData.data['2'],
        '3': responseData.data['3'],
        '4': responseData.data['4'],
    };

    const nextPermissions = responseData.data['4'].permissions;

    dispatch(setConnectSteps(nextConnectSteps));
    nextPermissions && dispatch(setPermissions(nextPermissions));
};

export const loadAccountStatus = createMyOwnAsyncThunk<{}, undefined>(
    'accountSlice/loadAccountStatus',
    loadAccountStatusBasic,
);

export const loadAccountStatusForce = createMyOwnAsyncThunk<{}, undefined>(
    'accountSlice/loadAccountStatusForce',
    loadAccountStatusBasic,
);

export const activateCode = createMyOwnAsyncThunk<{}, { code: string }>(
    'accountSlice/activateCode',
    async ({ code }, { dispatch }) => {
        const { data } = await AccountApi.activateCode({
            code,
        });

        setToken(data.access_token);
        setAccountIdLS(data.account_id);
        dispatch(loadAccountStatus());
        dispatch(setAuth(true));
    },
);

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setConnectSteps(state, action: { payload: ConnectSteps }) {
            return { ...state, connectSteps: action.payload };
        },
        setPermissions(state, action: { payload: Permission[] }) {
            return { ...state, permissions: action.payload };
        },
        resetAccountState() {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(activateCode.pending, handlePendingAction);
        builder.addCase(activateCode.fulfilled, handleFulfilledAction);
        builder.addCase(activateCode.rejected, (state, action) => handleRejectedAction(state, action.payload));
        //-- load status
        builder.addCase(loadAccountStatus.pending, (state) => {
            state.isLoadingStatus = true;
            state.error = undefined;
        });
        builder.addCase(loadAccountStatus.fulfilled, (state) => {
            state.isLoadingStatus = false;
            state.error = undefined;
        });
        builder.addCase(loadAccountStatus.rejected, (state, action) => {
            state.isLoadingStatus = false;
            state.error = action.payload;
        });
        builder.addCase(loadAccountStatusForce.pending, (state) => {
            state.isLoadingStatusForce = true;
            state.error = undefined;
        });
        builder.addCase(loadAccountStatusForce.fulfilled, (state) => {
            state.isLoadingStatusForce = false;
            state.error = undefined;
        });
        builder.addCase(loadAccountStatusForce.rejected, (state, action) => {
            state.isLoadingStatusForce = false;
            state.error = action.payload;
        });
        //-- load status
        builder.addCase(loadAccountPermissions.pending, (state) => {
            state.isLoadingPermissions = true;
            state.error = undefined;
        });
        builder.addCase(loadAccountPermissions.fulfilled, (state) => {
            state.isLoadingPermissions = false;
            state.error = undefined;
        });
        builder.addCase(loadAccountPermissions.rejected, (state, action) => {
            state.isLoadingPermissions = false;
            state.error = action.payload;
        });
    },
});

export const { setConnectSteps, setPermissions, resetAccountState } = accountSlice.actions;

export default accountSlice.reducer;
