import { configureStore } from '@reduxjs/toolkit';

import accountReducer, { AccountState } from './reducers/account-slice';
import authReducer, { AuthState } from './reducers/auth-slice';

export interface RootState {
    account: AccountState;
    auth: AuthState;
}

export default configureStore<RootState>({
    reducer: {
        account: accountReducer,
        auth: authReducer,
    },
});
