import { createSlice } from '@reduxjs/toolkit';

import { history } from '../../api/api';
import { clearAccountIdLS } from '../../helpers/account';
import { clearToken } from '../../helpers/token';
import { Routes } from '../../routes.enum';

export interface AuthState {
    isAuth: boolean;
}

const initialState: AuthState = {
    isAuth: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action: { payload: boolean }) => {
            state.isAuth = action.payload;
        },
        logout: (state) => {
            clearToken();
            clearAccountIdLS();
            state.isAuth = false;
            history.push(Routes.Activate);
        },
    },
});

export const { setAuth, logout } = authSlice.actions;

export default authSlice.reducer;
