import React from 'react';
import ConnectSteps from '../../partial/connect-steps/connect-steps';
import Icon from '../../base/icon/icon';
import { IconType } from '../../base/icon/icon.types';

interface ConnectStepTopProps {
    currentStep: number;
}

const ConnectStepPageTop: React.FC<ConnectStepTopProps> = ({ currentStep }) => (
    <div className="connect-step__top">
        <div className="connect-step__top-text">
            <div className="connect-step__top-icon">
                <Icon type={IconType.Connect} />
            </div>
            Connect your store
        </div>
        <ConnectSteps currentStep={currentStep} />
    </div>
);

export default ConnectStepPageTop;
