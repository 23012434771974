import React from 'react';
import LoaderImage from '../../../assets/images/loader.png';

import './loader.scss';

const Loader = () => {
    return (
        <div className="loader">
            <div className="loader__content">
                <img src={LoaderImage} />
            </div>
        </div>
    );
};

export default Loader;
