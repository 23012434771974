import React from 'react';
import { unstable_HistoryRouter as HistoryRouter, Navigate, Route, Routes as Switch } from 'react-router-dom';
import { Routes } from 'routes.enum';

import { history } from './api/api';
import RequireAuth from './components/pages/auth/require-auth';
import Layout from './components/partial/layout/layout';
import Activate from './pages/activate/activate';
import ConnectFirstStep from './pages/connect-first-step';
import ConnectSecondStep from './pages/connect-second-step';
import ConnectThirdStep from './pages/connect-third-step';
import SuccessConnected from './pages/success-connected';

import './index.scss';

const App: React.FC = () => (
    <HistoryRouter history={history}>
        <Switch>
            <Route path={Routes.Root} element={<Layout />}>
                <Route index element={<Navigate to={Routes.Activate} />} />
                <Route path={Routes.Activate} element={<Activate />} />
            </Route>
            <Route
                path={Routes.ConnectAccount}
                element={
                    <RequireAuth>
                        <Layout />
                    </RequireAuth>
                }
            >
                <Route path={Routes.ConnectFirstStep} element={<ConnectFirstStep />} />
                <Route path={Routes.ConnectSecondStep} element={<ConnectSecondStep />} />
                <Route path={Routes.ConnectThirdStep} element={<ConnectThirdStep />} />
                <Route path={Routes.SuccessConnected} element={<SuccessConnected />} />
            </Route>
            <Route path="*" element={<Navigate to={Routes.Activate} />} />
        </Switch>
    </HistoryRouter>
);

export default App;
