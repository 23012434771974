import { CSSProperties } from 'react';

export interface IconProps {
    type: IconType;
    className?: string;
    style?: CSSProperties;
    title?: string;
    onClick?: () => void;
    rotate?: boolean;
    width?: number;
    height?: number;
    id?: string;
}

export enum IconType {
    Check = 'check',
    CheckCircle = 'check-circle',
    Copy = 'copy',
    CrossCircle = 'cross-circle',
    Connect = 'connect',
    Vector = 'vector',
    CrossError = 'cross-error',
    Loading = 'loading',
}
