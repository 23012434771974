import { ConnectSteps } from '../api/models/ConnectSteps';
import { Routes } from '../routes.enum';

export const getActiveConnectStep = (accountSteps: ConnectSteps) =>
    Object.entries(accountSteps).find((step) => !step[1].status)?.[0] || '4';

export const getActiveConnectStepRoute = (accountSteps: ConnectSteps) => {
    const activeStep = getActiveConnectStep(accountSteps);

    switch (activeStep) {
        case '3':
            return Routes.ConnectSecondStep;
        case '4':
            if (accountSteps[activeStep].permissions.every((permission) => permission.status)) {
                return Routes.SuccessConnected;
            } else {
                return Routes.ConnectThirdStep;
            }
        default:
            return Routes.ConnectFirstStep;
    }
};

export function setAccountIdLS(accountId: number) {
    localStorage.setItem('account_id', `${accountId}`);
}

export function getAccountIdLS() {
    return localStorage.getItem('account_id');
}

export function clearAccountIdLS() {
    return localStorage.removeItem('account_id');
}
