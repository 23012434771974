import React from 'react';

import './content-centering.scss';

interface ContentCenteringProps {
    children?: React.ReactNode;
}

const ContentCentering: React.FC<ContentCenteringProps> = ({ children }) => (
    <div className="content-centering">{children}</div>
);

export default ContentCentering;
