import classNames from 'classnames';
import React, { ChangeEvent, MutableRefObject, useEffect } from 'react';
import { useIMask } from 'react-imask';

import './input.scss';

export interface InputProps {
    value?: string | number;
    onChange?: (e: string) => void;
    title?: string;
    placeholder?: string;
    className?: string;
    classNameLabel?: string;
    name: string;
    inputRef?: MutableRefObject<HTMLInputElement> | any;
    fullWidth?: boolean;
    mask?: string;
    error?: string;
}

const Input: React.FC<InputProps> = ({
    inputRef,
    value: valueProps,
    onChange,
    title,
    placeholder,
    className = '',
    classNameLabel = '',
    name,
    fullWidth,
    mask,
    error,
}) => {
    const {
        ref: maskRef,
        value: valueMask,
        setValue,
    } = useIMask({
        mask: mask || '',
        placeholderChar: '_',
    });

    useEffect(() => {
        onChange?.(valueMask);
    }, [onChange, valueMask]);

    const inputValue = mask ? valueMask : valueProps;
    const currentRef = mask ? maskRef : inputRef;
    const onChangeValue = (e: ChangeEvent<HTMLInputElement>) =>
        mask ? setValue(e.target.value) : onChange?.(e.target.value);

    return (
        <label
            className={classNames(classNameLabel, 'input-label', {
                'input-label--full-width': fullWidth,
            })}
            htmlFor={`input-${name}`}
        >
            {title && <span className="input-label__title">{title}</span>}
            <input
                ref={currentRef as MutableRefObject<HTMLInputElement>}
                name={name}
                id={`input-${name}`}
                placeholder={placeholder}
                className={`input-label__input ${className} ${fullWidth ? 'input--full-width' : ''}`}
                value={inputValue}
                onChange={onChangeValue}
                type="text"
            />
            {error && <div className="input-label__error">{error}</div>}
        </label>
    );
};

export default Input;
