import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router';

import { getActiveConnectStepRoute } from '../../../helpers/account';
import { loadAccountStatus } from '../../../store/reducers/account-slice';
import { RootState } from '../../../store/store';
import ContentCentering from '../../partial/content-centering/content-centering';
import ConnectStepPageTop from './connect-step-top';

import './connect-step.scss';

interface ConnectStepProps {
    children: React.ReactNode | string;
    currentStep: number;
}

const ConnectStepPage: React.FC<ConnectStepProps> = ({ children, currentStep }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { connectSteps } = useSelector((state: RootState) => state.account);

    useEffect(() => {
        if (!connectSteps) {
            dispatch(loadAccountStatus());
        } else if (connectSteps) {
            const activeStepRoute = getActiveConnectStepRoute(connectSteps);
            const isActualStep = !!matchPath({ path: activeStepRoute }, location.pathname);

            !isActualStep && navigate(activeStepRoute);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectSteps, dispatch, navigate]);

    useEffect(() => window.scrollTo?.(0, 0), [location]);

    return (
        <div className="connect-step">
            <ConnectStepPageTop currentStep={currentStep} />
            <ContentCentering>{children}</ContentCentering>
        </div>
    );
};

export default ConnectStepPage;
