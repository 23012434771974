import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { resetAccountState } from 'store/reducers/account-slice';
import { logout } from 'store/reducers/auth-slice';

import { Routes } from '../../../routes.enum';
import Button from '../../base/button/button';
import logoSrc from './../../../assets/images/logo.svg';

import './header.scss';

const Header = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const isVisibleAnotherCodeButton =
        !!matchPath({ path: Routes.ConnectFirstStep }, pathname) ||
        !!matchPath({ path: Routes.ConnectSecondStep }, pathname) ||
        !!matchPath({ path: Routes.ConnectThirdStep }, pathname);

    const handleClickAnotherCodeButton = () => {
        dispatch(logout());
        dispatch(resetAccountState());
    };

    return (
        <div className="header">
            <div
                className={classNames('header__logo-wrapper', {
                    'header__logo-wrapper--centering': !isVisibleAnotherCodeButton,
                })}
            >
                <img src={logoSrc} alt="logo" />
            </div>
            <div className="header__controls">
                {isVisibleAnotherCodeButton && (
                    <Button
                        className="header__another-code-button"
                        onClick={handleClickAnotherCodeButton}
                        style="white"
                    >
                        Enter another code
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Header;
