import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import './modal.scss';

export interface ModalProps {
    onClose: () => void;
    children?: React.ReactNode;
    confirmButtonText?: string;
    onConfirm?: () => void;
}

export const Modal: React.FC<ModalProps> = ({ children, onClose, confirmButtonText, onConfirm }) => (
    <div className="modal">
        <div className="modal__bg" />
        <OutsideClickHandler display="contents" onOutsideClick={onClose}>
            <div className="modal__content-wrapper">
                <div className="modal__content">{children}</div>
                <div className="modal__controls">
                    <button className="modal__button" onClick={onClose}>
                        Close
                    </button>

                    {confirmButtonText && (
                        <button className="modal__button modal__button--confirm" onClick={onConfirm}>
                            {confirmButtonText}
                        </button>
                    )}
                </div>
            </div>
        </OutsideClickHandler>
    </div>
);
